$grid-columns: 12;
$grid-gutter-width: 16px;
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1220px,
);
$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1180px,
);

@import '~bootstrap/scss/bootstrap-grid.scss';